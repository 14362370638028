import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const Fuel = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 offset-lg-1 order-md-last'>
            <div className='d-flex justify-content-center justify-content-lg-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra11-marketplace/image.webp'
                altDescription='Tela de celular mostrando como pagar com Shell Box pelo Super App do Inter ao lado de uma bomba de gasolina.'
                arrayNumbers={[ 312, 335, 463, 563 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Abasteça e ganhe cashback</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Use a funcionalidade Shell Box no Super App pra pagar ao abastecer em postos Shell e receba parte do valor pago de volta.Aproveite também a Tag do Inter e economize tempo em pedágios e estacionamentos.</p>
            <Link
              title='Aproveitar agora'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              to='https://inter.co/pra-voce/shell-box/'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_11',
                  element_action: 'click button',
                  element_name: 'Aproveitar agora',
                  section_name: 'Abasteça e ganhe cashback',
                  redirect_url: 'https://inter.co/pra-voce/shell-box/',
                })
              }}
            >
              Aproveitar agora
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Fuel
