import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const GamerWorld = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5'>
            <div className='d-flex justify-content-center justify-content-lg-start mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra10-marketplace/image.webp'
                altDescription='Homem e mulher sorridentes enquanto estão sentados jogando video game'
                arrayNumbers={[ 285, 285, 373, 365 ]}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Tudo pra transformar seu Mundo Gamer</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Dê um up no seu setup gamer, compre recarga de jogos e muito mais e ainda aproveite vantagens exclusivas.</p>
            <Link
              title='Mundo Gamer Inter'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              to='https://inter-co.onelink.me/Qyu7/oy0exri8'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_10',
                  element_action: 'click button',
                  element_name: 'Mundo Gamer Inter',
                  section_name: 'Tudo pra transformar seu Mundo Gamer',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/oy0exri8',
                })
              }}
            >
              Mundo Gamer Inter
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default GamerWorld
