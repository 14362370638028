import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { Section } from './style'

const InternationalChip = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last'>
            <div className='d-flex justify-content-center justify-content-md-end mb-3 mb-md-0'>
              {width < WIDTH_MD
                ? <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-mobile-marketplace/image.webp'
                    altDescription='Homem de meia idade apoiado num parapeito enquanto usa o celular.'
                    arrayNumbers={[ 310, 242, 374, 452 ]}
                  />
                : <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra6-1440-marketplace/image.webp'
                    altDescription='Homem de meia idade apoiado num parapeito enquanto usa o celular.'
                    arrayNumbers={[ 310, 242, 374, 452 ]}
                  />
                }
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Chip internacional pra ficar sempre conectado</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Antes de viajar pro exterior, garanta seu chip internacional Airalo. É só ativar sua Conta Global e comprar direto pelo Super App.</p>
            <Link
              title='Abrir Conta Global'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              to='https://inter-co.onelink.me/Qyu7/kdbcymgk'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Abrir Conta Global',
                  section_name: 'Chip internacional pra ficar sempre conectado',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/kdbcymgk',
                })
              }}
            >
              Abrir Conta Global
            </Link>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default InternationalChip
