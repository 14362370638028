import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const Shopping = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 bg-gray'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-2 order-md-last'>
            <div className='d-flex justify-content-center justify-content-md-end mb-3 mb-md-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-768-360-marketplace/image.webp'
                altDescription='Tela de celular mostrando vários produtos dentro do Shopping do Inter, como celular, TV, notebook e maiss'
                arrayNumbers={[ 312, 322, 357, 620 ]}
                className='d-lg-none d-xl-inline'
              />
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-1440-marketplace/image.webp'
                altDescription='Tela de celular mostrando vários produtos dentro do Shopping do Inter, como celular, TV, notebook e maiss'
                arrayNumbers={[ 312, 322, 357, 620 ]}
                className='d-none d-lg-inline d-xl-none'
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-'>
            <h2 className='fs-24 fs-md-28 fs-xl-48 lh-28 lh-md-34 lh-xl-52 text-grayscale--500 fw-500 mt-3'>Um shopping inteirinho na palma da sua mão</h2>
            <p className='fs-16 fs-lg-18 lh-20 lh-lg-22 text-grayscale--400 mt-3'>Aqui você encontra as melhores ofertas nas maiores lojas do Brasil e recebe cashback direto na sua conta.</p>
            <a
              title='Aproveitar o Shopping'
              className='btn btn--lg rounded-2 fs-10 fw-700 text-none mt-2 mt-md-1'
              href='https://inter-co.onelink.me/Qyu7/j5xuf9mr'
              target='_blank'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Aproveitar o Shopping',
                  section_name: 'Um shopping inteirinho na palma da sua mão',
                  redirect_url: 'https://inter-co.onelink.me/Qyu7/j5xuf9mr',
                })
              }} rel='noreferrer'
            >
              Aproveitar o Shopping
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Shopping
